import {
  Hub, EventProcessor, IntegrationClass,
} from '@sentry/types';
import { getCurrentHub } from '@sentry/core';
import { getSentryLevel } from './utils';

import type { Integration as SentryIntegration } from '@sentry/types';

export interface IntegrationOptions {
}

export abstract class Integration implements SentryIntegration {

  static getCurrentHub: () => Hub = getCurrentHub;

  public name: string;

  abstract setupOnce(
    addGlobalEventProcessor?: ( callback: EventProcessor ) => void
  ): void;

  public constructor( options?: IntegrationOptions ) {
    Object.assign( this, options );
  }

  getSentryLevel = getSentryLevel;

  getCurrentHub(): Hub { return Integration.getCurrentHub(); }

  getIntegrationClass<T extends Integration>() {
    return this.constructor as IntegrationClass<T>;
  }
  getSelf<T extends Integration = Integration>() {
    return this.getCurrentHub().getIntegration( this.getIntegrationClass<T>() );
  }
  isActive() { return !! this.getSelf(); }

}
