import { SeverityLevel } from '@sentry/types';

import { configureScope } from './scope';

import type { Hub } from '@sentry/core';

import type { ScopeOptions } from './scope';

export function getSentryLevel( level: string ): SeverityLevel {
  switch ( level ) {
    case 'fatal': return 'fatal';
    case 'debug': return 'debug';
    case 'error': return 'error';
    case 'info': return 'info';
    case 'warn': return 'warning';
    case 'warning': return 'warning';
    default: return 'debug';
  }
}

export const IGNORED_ERROR_CODES = [ 401, 404 ];
export const IGNORED_ERROR_NAMES = [ 'NotAuthenticated' ];
export function isRecordable( error: Error ) {
  if ( ! ( error instanceof Error ) ) return false;
  const err = error as Error & {
    code?: number;
    statusCode?: number;
    report_to_sentry?: boolean;
  };
  if ( typeof err.report_to_sentry === 'boolean' ) return err.report_to_sentry;
  for ( const x of [ 'code', 'statusCode' ] as const ) {
    if ( err[x] && IGNORED_ERROR_CODES.includes( err[x] ) ) return false;
  }
  if ( IGNORED_ERROR_NAMES.includes( err.name ) ) return false;
  return true;
}

export function beforeSend( event, hint ) {
  if ( ! isRecordable( hint.originalException ) ) return null;
  return event;
}

export type RecordErrorOptions = ScopeOptions & {
  // The & any is because the client and server have different Hub
  // implementations
  hub: Hub & any;
};
export function recordErrorCommon( error: Error, options: RecordErrorOptions ) {
  if ( ! isRecordable( error ) ) return;
  const { hub, ...opts } = options;

  let errid;
  hub.withScope( scope => {
    scope.setLevel( 'error' );
    scope.setExtra( 'error', error );
    configureScope( scope, opts );
    errid = hub.captureException( error );
  } );
  ( error as any ).sentry_id = errid;
  log.debug( 'REPORTED ERROR ID:', errid, String( error ) );
  return errid;
}
