const DEBUG = require( 'debug' );

/**
 * @typedef {object} DebugOptions
 * @property {string} [description] - Description of the debug option
 * @property {string} [env] - Environment variable. If
 * `process.env.<env>` or `window.localStorage.<env>` are truthy then
 * this flag will be enabled.
 */
const registry = {};
const ENABLED = new Set();
/**
  * Register a debug flag.
  *
  * @private
  * @param {string} name
  * @param {string|DebugOptions} [opts]
  */
function register( name, opts ) {
  if ( typeof opts === 'string' ) opts = { description : opts };
  if ( ! opts ) opts = {};
  if ( ! registry[ name ] ) registry[ name ] = {};
  Object.assign( registry[ name ], opts );
}

function get_item( item ) {
  for ( const key of [ item, item.toUpperCase(), item.toLowerCase() ] ) {
    if ( typeof process !== 'undefined' && 'env' in process ) {
      if ( process.env[ key ] ) return process.env[ key ];
    }
    if ( DEBUG.storage ) {
      try {
        if ( DEBUG.storage.hasItem( key ) ) {
          return DEBUG.storage.getItem( key );
        }
      } catch ( error ) {
        // ignore
      }
    }
  }
}

function set_item( item, value ) {
  for ( const key of [ item, item.toUpperCase(), item.toLowerCase() ] ) {
    if ( typeof process !== 'undefined' && 'env' in process ) {
      delete process.env[ key ];
    }
    if ( DEBUG.storage ) {
      try { DEBUG.storage.removeItem( key ); } catch ( error ) { /* ignore */ }
    }
  }
  if ( value ) {
    if ( typeof process !== 'undefined' && 'env' in process ) {
      process.env[ item ] = value;
    }
    if ( DEBUG.storage ) {
      try {
        DEBUG.storage.setItem( item, value );
      } catch ( error ) { /* ignore */ }
    }
  }
}

function load() {
  const set = ( key ) => {
    if ( typeof key !== 'string' ) return;
    for ( const k of key.split( ',' ) ) ENABLED.add( k.trim() );
  };
  set( get_item( 'DEBUG' ) );
  for ( const [ key, opts ] of Object.entries( registry ) ) {
    if ( opts.env ) {
      if ( get_item( opts.env ) ) set( key );
    }
  }
  return Array.from( ENABLED ).join( ',' );
}
function save( namespaces = '' ) {
  namespaces = String( namespaces );
  const flags = new Set( namespaces.split( ',' ).map( n => n.trim() ) );
  for ( const [ key, opts ] of Object.entries( registry ) ) {
    if ( opts.env ) {
      if ( flags.has( key ) ) {
        set_item( opts.env, key );
        flags.delete( key );
      }
    }
  }
  set_item( 'DEBUG', Array.from( flags ).join( ',' ) );
}


/**
 * Enable debug flags.  This will *replace* the existing set of flags.
 *
 * @param {string[]} flags - The flags to enable.
 */
function enable( ...flags ) { DEBUG.enable( flags.join( ',' ) ); }
/**
 * Disable debug flags.  This disables all of them and returns the set
 * of flags that was enabled.
 */
function disable() { return DEBUG.disable(); }

/** Indicate whether a debug flag is enabled. */
function is_enabled( key ) { return ENABLED.has( key ); }

/** Enable or disable a specific flag. */
function flag( key, value ) {
  if ( value ) {
    ENABLED.add( key );
  } else {
    ENABLED.delete( key );
  }
}

Object.assign( module.exports, {
  registry, register,
  enable, disable,
  is_enabled, flag,
  load, save,
} );
