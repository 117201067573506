const listeners = new Map();

function on( event, fn ) {
  if ( ! listeners.has( event ) ) listeners.set( event, new Set() );
  listeners.get( event ).add( fn );
  return () => off( event, fn );
}
function off( event, fn ) {
  const ears = listeners.get( event );
  if ( ! ears ) return;
  ears.delete( fn );
}

function once( event, fn ) {
  const wrapper = () => {
    fn();
    off( event, wrapper );
  };
  on( event, wrapper );
}

function emit( event, data ) {
  const ears = listeners.get( event );
  if ( ! ears ) return false;
  for ( const ear of ears ) ear( data );
  return true;
}

function listenerCount( event ) {
  const ears = listeners.get( event );
  if ( ! ears ) return 0;
  return ears.size();
}

module.exports = { on, off, once, emit, listenerCount };
