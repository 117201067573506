import { Integration } from './Integration';

export class Logging extends Integration {
  public static id: string = 'Logging';
  public name: string = Logging.id;

  public setupOnce(): void {
    if ( ! SSP?.events ) return;
    SSP.events.on( 'log', this.handleLogEvent.bind( this ) );
  }

  handleLogEvent( { data, message, level } ) {
    const hub = this.getCurrentHub();
    if ( ! hub.getIntegration( Logging ) ) return;
    level = this.getSentryLevel( level );
    hub.addBreadcrumb( {
      category  : 'console',
      level     : this.getSentryLevel( level ),
      message, data,
    } );
  }

}
