import { Integration } from './Integration';
import { fill } from '@sentry/utils';
import { format } from '@ssp/logger';

export class Console extends Integration {
  static id: string = 'Console';
  name: string = Console.id;

  setupOnce(): void {
    for ( const level of [ 'debug', 'info', 'warn', 'error', 'log' ] ) {
      fill( console, level, this.createConsoleWrapper( level ) );
    }
  }

  createConsoleWrapper( level: string ) {
    const sentryLevel = this.getSentryLevel( level );
    const getHub = () => this.getCurrentHub();
    return function consoleWrapper( original: () => void ): () => void {
      return function __sentry_wrapped_console( ...args: any ) {
        const hub = getHub();
        if ( hub.getIntegration( Console ) ) {
          hub.addBreadcrumb( {
            category  : 'console',
            level     : sentryLevel,
            message   : format( ...args ),
          }, { input: [ ...args ], level } );
        }
        return original.apply( this, args );
      };
    };
  }

}
