import pino, { Logger } from 'pino';

export const logger = pino( {
  name    : 'ssp-webapp',
  level   : 'trace',
  browser : {
    /*
    transmit  : {
      level : 'warn',
      send( level, logEvent: LogEvent ) {},
    },
    write( obj ) {},
    */
  },
} );
export default logger;

export { Logger } from 'pino';
export { format, inspect } from './formatter';

if ( typeof global !== 'undefined' ) {
  if ( ! global.log ) global.log = logger;
  if ( ! global.logger ) global.logger = logger;
}
if ( typeof window !== 'undefined' ) {
  if ( ! window.log ) window.log = logger;
  if ( ! window.logger ) window.logger = logger;
}

declare global {
  interface Window {
    logger: Logger;
  }
}
