/* global globalThis */

// This must be loaded before anything could possibly import 'debug'
const DEBUG = require( './lib/debug-hooks' );

const SSP = {};
module.exports = SSP;

require( '@abraham/reflection' );

const utils = {};

utils.register = ( /** @type {*} */ add ) => Object.assign( utils, add );
attach( SSP, 'attach', attach.bind( SSP ) );

SSP.attach( 'meta', {} );
SSP.attach( 'utils', utils );

SSP.attach( utils, 'attach', attach.bind( utils ) );
SSP.utils.attach( 'debug', DEBUG );

attach( SSP, 'events', require( './lib/events' ) );

if ( typeof global !== 'undefined' ) attach( global, 'SSP', SSP );
if ( typeof window !== 'undefined' ) attach( window, 'SSP', SSP );
if ( typeof globalThis !== 'undefined' ) attach( globalThis, 'SSP', SSP );

/**
 * @param {any} target
 * @param {string} name
 * @param {any} value
 */
function attach( target, name, value ) {
  // This allows this function to be bound to a target so that if you
  // call `SSP.attach( 'thing', {} )` then it attaches to the SSP
  // object, but if the first argument isn't a string then it works as
  // normal.
  if ( typeof target === 'string' ) {
    value = name;
    name = target;
    target = this;
  }
  Object.defineProperty( target, name, {
    configurable  : false,
    writable      : false,
    enumerable    : ! name.startsWith( '_' ),
    value,
  } );
}
