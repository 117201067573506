import * as Sentry from '@sentry/react';
import {
  BrowserClient, BrowserTracing, Breadcrumbs, GlobalHandlers,
  LinkedErrors, defaultStackParser, getCurrentHub, makeFetchTransport,
  reactRouterV5Instrumentation, Replay,
} from '@sentry/react';
import { Dedupe, ExtraErrorData } from '@sentry/integrations';

import { Console, Logging, beforeSend, recordErrorCommon } from '../common';
import { dsn } from './dsn';
import './react-patch';

import type { RecordErrorOptions } from '../common';

const client = new BrowserClient( {
  dsn,
  transport     : makeFetchTransport,
  stackParser   : defaultStackParser,
  // tunnel        : '/api/tunnel/sentry', // TODO
  environment   : SSP.env,
  release       : SSP.release,
  integrations  : [
    new Breadcrumbs(),
    new GlobalHandlers(),
    new LinkedErrors(),
    new Replay(),
    new Console(),
    new Logging(),
    new BrowserTracing( {
      routingInstrumentation : reactRouterV5Instrumentation( history ),
    } ),
    new ExtraErrorData(),
    new Dedupe(),
  ],
  sampleRate                : percent( 100 ),
  tracesSampleRate          : percent( 20 ),
  replaysSessionSampleRate  : percent( 10 ),
  replaysOnErrorSampleRate  : percent( 100 ),
  maxBreadcrumbs            : 100,
  // tracesSampler( sampleContext ) {},
  autoSessionTracking : true,
  allowUrls           : [
    'ssp.boozallencsn.com',
    'test-ssp.boozallencsn.com',
    'dev-ssp.boozallencsn.com',
    'localhost',
  ],
  beforeSend,
} );

getCurrentHub().bindClient( client );

// @ts-ignore - This errors because the type of SSP.Sentry is declared
// using the server version, and the browser version is missing some
// things from there.
SSP.Sentry = Sentry;
export { Sentry };

function percent( value: number ) { return value / 100; }

/** Report an error to Sentry. */
export function recordError(
  error: Error, options: Partial<RecordErrorOptions> = {},
): string {
  return recordErrorCommon( error, { hub : getCurrentHub(), ...options } );
}
