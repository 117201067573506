import _ from 'lodash';
import { inspect as _inspect } from 'util';

export function inspect( obj: any, opts: $TSFixMe = {} ) {
  const type = typeof obj;
  if ( type === 'string' ) return obj;
  if ( type === 'number' || type === 'boolean' ) return String( obj );

  if ( _.isUndefined( obj ) ) return '<undefined>';
  if ( _.isNull( obj ) ) return '<null>';
  return _inspect( obj, { getters : true, depth : 3, ...opts } );
}

export function format( ...args: any[] ): string {
  return args.map( inspect ).join( ' ' );
}

export function format_with_data( ...args: any[] ) {
  try {
    const data = get_data( args );
    const message = format( ...args );
    return [ data, message ];
  } catch ( error ) {
    // eslint-disable-next-line no-console
    console.error( 'FORMAT ERROR:', error );
  }
}
function get_data( args: any[] ) {
  if ( _.isError( args[0] ) ) return { err : args.shift() };
  if ( _.isPlainObject( args[0] ) ) return args.shift();
  return {};
}
