import _ from 'lodash';
import {
  Hub, ScopeContext, Span, Scope, Primitive, Contexts,
} from '@sentry/types';

export type Tags = Record<string, Primitive>;

export interface ScopeOptions extends Partial<ScopeContext> {
  transaction?: string;
  span?: Span;
  hub?: Hub;
  /* inherited from ScopeContext
  user?: User;
  level?: SeverityLevel;
  extra?: Extras;
  contexts?: Contexts;
  tags?: Tags;
  fingerprint?: string[];
  requestSession?: RequestSession;
  */
  record?: ( error_id: string ) => void;
}

export function configureScope( scope: Scope, options: ScopeOptions = {} ) {
  const { transaction, span, contexts, ...opts } = options;
  if ( transaction ) scope.setTransactionName( transaction );
  if ( span ) scope.setSpan( span );
  scope.update( opts );
  _.each( contexts, ( val, key ) => {
    const context = normalizeContext( key, val );
    if ( context ) scope.setContext( key, context );
  } );
}

const isPrimitive = _.overSome( [ _.isString, _.isBoolean, _.isInteger ] );
export function normalizeContext( key: string, data: any ): Contexts {
  if ( _.isNil( data ) ) return;
  if ( _.isPlainObject( data ) && _.every( data, isPrimitive ) ) return data;
  if ( typeof data === 'object' && typeof data.toLinkData === 'function' ) {
    return data.toLinkData();
  }
  // If it isn't something we could turn into a context item then just
  // warn about it and skip it
  log.warn( `Invalid Sentry context for '${key}' (${data})` );
  return;
}
